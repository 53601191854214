import React, { useState, useEffect } from "react";
import {
  getOrderTypeName,
  getProductsCount,
  parseOrderDetails,
} from "../../Services/Helpers";

const OrderItemCard = (props) => {
  const { item, onOrderSelection, orderList } = props;

  const [orderAddress, setOrderAddress] = useState({ attributes: [] });
  const [productList, setProductList] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    const details = parseOrderDetails(item, orderList);
    if (details.productList) {
      setProductList(details.productList);
    }
    if (details.address) {
      setOrderAddress(details.address);
    }
  }, [selectedOrder]);

  const handleItemClick = (item, orderList) => {
    console.log("item", item);
    console.log("activeItem", activeItem);
    if (activeItem === null || item["id"] !== activeItem["id"]) {
      onOrderSelection(item, orderList);
      setSelectedOrder(item);

      // Remove active state from all other items
      const elements = document.querySelectorAll(".order_item-card.active");
      elements.forEach((element) => {
        element.classList.remove("active");
      });

      const updatedActiveItem = activeItem === item ? null : item;
      setActiveItem(updatedActiveItem);
    }
  };

  const getOrderItemClass = (item) => {
    return `order_item-card ${activeItem === item ? "active" : ""}`;
  };

  return (
    <>
      <div
        className={getOrderItemClass(item)}
        onClick={() => handleItemClick(item, orderList)}
      >
        <div className="order_item-card--header">
          <h3>{getOrderTypeName(item.attributes["order_type"])}</h3>
          <h3>#{item["id"]}</h3>
        </div>
        <div className="order_item-card--body">
          <h3>{orderAddress.attributes["order.address.firstname"]}</h3>
          <h4>{getProductsCount(item)} Items</h4>
        </div>
      </div>
    </>
  );
};

export default OrderItemCard;
