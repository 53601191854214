import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import KitchenOrder from "./Pages/KitchenOrder";
import Login from "./Pages/Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Wrapper from "./OrderFulfilment/Pages/Wrapper";
import Bill from "./OrderFulfilment/Pages/Bill";

function App() {
  return (
    <>
      <ToastContainer autoClose={3000} hideProgressBar enableHtml={true} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Wrapper />} />
          <Route path="/KitchenOrder" element={<KitchenOrder />} />
          <Route path="/login" exact element={<Login />} />
          <Route path="/bill" exact element={<Bill />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
