import React, { useEffect, useState } from "react";
import OrderItemCard from "../Components/OrderItemCard";
import { API_ORDER_LIST } from "../../Config/Endpoints";
import { getUserData } from "../../Services/Storage";
import {
  PAY_RECEIVED,
  STAT_DELIVERED,
  STAT_PENDING,
} from "../../Config/Constants";
import { Code } from "react-content-loader";

let userData = getUserData();
const CompletedOrders = (props) => {
  const { onOrderSelection, orderAction } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [orderList, setOrderList] = useState([]);
  const [orderListAll, setOrderListAll] = useState([]);

  const fetchOrderList = () => {
    setIsLoading(true);
    // fetch(API_ORDER_LIST + `?page=${currentPage}&limit=${limit}`, {
    //&filter[%3E%3D][order.ctime]=1686732590
    fetch(
      API_ORDER_LIST +
      `?include=order/product,order/address&filter[%3D%3D][order.statusdelivery]=${STAT_DELIVERED}&filter[%3D%3D][order.statuspayment]=${PAY_RECEIVED}`,
      {
        method: "GET",
        crossDomain: true,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + userData.token,
        },
      }
    )
      .then((response) => {
        if (response.status === 401) {
          // alert('Unauthorized access!');
          console.log();
          if (userData.isLoggedIn) {
            logoutUser();
          }
        }
        return response.json();
      })
      .then((data) => {
        // console.log("orderList", data.data);
        setOrderList(data.data);
        setOrderListAll(data);
        setIsLoading(false);
        // console.log(orderList);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchOrderList();
  }, [orderAction]);

  return (
    <>

      {isLoading ? (
        <>
          <Code />
        </>
      ) : (
        orderList.map((item, key) => (
          <OrderItemCard
            key={key}
            item={item}
            onOrderSelection={onOrderSelection}
            orderList={orderListAll}
          />
        ))
      )}
      <div className="empty" >
        <p >
          No Orders
        </p>
      </div>
    </>
  );
};

export default CompletedOrders;
