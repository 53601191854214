import React from "react";
import { parseAmount } from "../../Services/Helpers";

const Bill = (props) => {
  const { currentOrder, orderAddress, productList } = props;
  return (
    <>
      <table
        style={{
          padding: "10px",
          width: "100%",
          maxWidth: "500px",
          margin: "0 auto",
        }}
      >
        <thead>
          <tr>
            <th>
              <h2
                style={{
                  fontSize: "25px",
                  color: "#000",
                  fontWeight: "600",
                  marginBottom: "0",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Your Order Number {currentOrder["id"]}
              </h2>
              <p
                style={{
                  fontSize: "16px",
                  color: "#000",
                  fontWeight: "500",
                  marginBottom: "10px",
                  textAlign: "center",
                }}
              >
                Cottage Butcher,
                <br />
                3173 Muskoka District Road 169, Bala
                <br />
                ON P0C 1A0, Canada
                <br />
                705-762-5995
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p
                style={{ fontSize: "16px", color: "#000", marginBottom: "0px" }}
              >
                ORD: #{currentOrder["id"]}
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <p
                style={{ fontSize: "16px", color: "#000", marginBottom: "0px" }}
              >
                Date/Time:{currentOrder.attributes["order.ctime"]}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <table style={{ width: "100%", marginTop: "10px" }}>
                <thead
                  style={{ fontSize: "16px", color: "#000", textAlign: "left" }}
                >
                  <tr>
                    <th style={{ borderBottom: "1px solid #000" }}>QTY ITEM</th>
                    <th style={{ borderBottom: "1px solid #000" }}>TOTAL</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "17px", color: "#000" }}>
                  {productList.map((item, key) => (
                    <tr key={key}>
                      <td
                        style={{
                          borderBottom: "1px solid #adababd6",
                          padding: "7px 0",
                        }}
                      >
                        {item.attributes["order.product.name"]} X{" "}
                        {item.attributes["order.product.quantity"]}
                      </td>
                      <td
                        style={{
                          borderBottom: "1px solid #adababd6",
                          padding: "7px 0",
                        }}
                      >
                        $
                        {parseAmount(
                          parseFloat(
                            item.attributes["order.product.quantity"]
                          ) * parseFloat(item.attributes["order.product.price"])
                        )}
                      </td>
                    </tr>
                  ))}

                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid #adababd6",
                        padding: "7px 0",
                      }}
                    >
                      Amount
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #adababd6",
                        padding: "7px 0",
                      }}
                    >
                      ${parseAmount(currentOrder.attributes["order.price"])}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid #adababd6",
                        padding: "7px 0",
                      }}
                    >
                      Taxes
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #adababd6",
                        padding: "7px 0",
                      }}
                    >
                      ${parseAmount(currentOrder.attributes["order.taxvalue"])}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid #adababd6",
                        padding: "7px 0",
                      }}
                    >
                      Tip
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #adababd6",
                        padding: "7px 0",
                      }}
                    >
                      ${parseAmount(currentOrder.attributes["tip_amount"])}
                    </td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        borderBottom: "1px solid #adababd6",
                        padding: "7px 0",
                      }}
                    >
                      Total
                    </td>
                    <td
                      style={{
                        borderBottom: "1px solid #adababd6",
                        padding: "7px 0",
                      }}
                    >
                      $
                      {parseAmount(
                        currentOrder.attributes["order.final_amount"]
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "16px",
                  color: "#000",
                  marginTop: "20px",
                }}
              >
                We value your feedback. Visit us on:
                <br />
                https://cottagebutcher.com/
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Bill;
