const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const API_LOGIN = `${baseUrl}/api/login`;
export const API_REGISTER = `${baseUrl}/api/register`;
export const API_PROFILE_UPDATE = `${baseUrl}/api/profile`;
export const API_GET_ADDRESS = `${baseUrl}/jsonapi/customer?related=address`;
export const API_GET_CATALOG = `${baseUrl}/jsonapi/catalog`;
export const API_GET_PRODUCTS = `${baseUrl}/jsonapi/product`;
export const API_GET_CART = `${baseUrl}/jsonapi/basket`;
export const API_PLACE_ORDER = `${baseUrl}/jsonapi/order`;
export const API_CART_CHECKOUT = `${baseUrl}/api/checkout`;
export const API_ORDER_UPDATE = `${baseUrl}/api/order/update`;
export const API_GET_ORDERS = `${baseUrl}/api/order/list`;
export const API_UPDATE_ORDER_PRODUCT_STATUS = `${baseUrl}/api/order/product/update`;
export const API_ORDER_LIST = `${baseUrl}/jsonapi/order`;
export const API_ORDER_DELIVERY_STATUS = `${baseUrl}/api/order/update-delivery-status`;
export const API_CANCEL_ORDER = `${baseUrl}/api/order/cancel`;
