import React, { useEffect, useState } from "react";
import Header from "../Components/Header";
import Aside from "./Aside";
import ActiveOrders from "./ActiveOrders";
import ScheduledOrders from "./ScheduledOrders";
import CompletedOrders from "./CompletedOrders";
import { getUserData } from "../../Services/Storage";
const loggedInUser = getUserData();

const Wrapper = () => {
  const [currentTab, setCurrentTab] = useState("active");
  const [currentOrder, setCurrentOrder] = useState({});
  const [orderList, setOrderList] = useState({});
  const [orderAction, setOrderAction] = useState(false);

  useEffect(() => {
    if (!loggedInUser.isLoggedIn) {
      window.location = "/login";
    }
  });

  const onTabSelection = (tab) => {
    setCurrentTab(tab);
    setCurrentOrder({});
  };

  const onOrderSelection = (order, orderList) => {
    setCurrentOrder(order);
    setOrderList(orderList);
  };

  const onOrderAction = (item) => {
    console.log("Action Happend");
    setOrderAction(item);
    setCurrentOrder({});
  };

  return (
    <>
      <section className="wrapper">
        <Header onTabSelection={onTabSelection} />
        <div className="wrapper-body">
          <Aside
            currentOrder={currentOrder}
            orderList={orderList}
            onOrderAction={onOrderAction}
          />
          <div className="outer">
            {currentTab === "active" && (
              <ActiveOrders
                onOrderSelection={onOrderSelection}
                orderAction={orderAction}
              />
            )}
            {currentTab === "scheduled" && (
              <ScheduledOrders
                onOrderSelection={onOrderSelection}
                orderAction={orderAction}
              />
            )}
            {currentTab === "completed" && (
              <CompletedOrders
                onOrderSelection={onOrderSelection}
                orderAction={orderAction}
              />
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Wrapper;
