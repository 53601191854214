//delivery Statuses
export const STAT_UNFINISHED = -1;
export const STAT_DELETED = 0;
export const STAT_PENDING = 1;
export const STAT_PROGRESS = 2;
export const STAT_DISPATCHED = 3;
export const STAT_DELIVERED = 4;
export const STAT_LOST = 5;
export const STAT_REFUSED = 6;
export const STAT_RETURNED = 7;

//payment Statuses
export const PAY_UNFINISHED = -1;
export const PAY_DELETED = 0;
export const PAY_CANCELED = 1;
export const PAY_REFUSED = 2;
export const PAY_REFUND = 3;
export const PAY_PENDING = 4;
export const PAY_AUTHORIZED = 5;
export const PAY_RECEIVED = 6;
export const PAY_TRANSFERRED = 7;
