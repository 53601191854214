import React from "react";

const Table = (props) => {
  return (
    <>
      {/* <div className="card-header">
                <h2 className="order__id">{props.IdNumber}</h2>
                <p className="complete-order--items">1/{props.ItemsBooking}</p>
                <p className="complete--timing-order">{props.ItemsComplete}</p>
            </div> */}
      <div className="card-header">
        {/* <TableNumber /> */}
        <div className="id-number">
          <h2 className="order__id">{props.IdNumber}</h2>
          {/* <p className="complete-order--items">1/{props.ItemsBooking}</p> */}
          <p className="complete--timing-order">{props.ItemsComplete}</p>
        </div>
        <h2 className="take-away">{props.TableCardIn}</h2>
      </div>
    </>
  );
};

export default Table;
