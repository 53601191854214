import React, { useEffect, useState } from "react";
import CardPrice from "../Components/CardPrice";
import DisableCard from "../Components/DisableCard";
import TableNumber from "../Components/TableNumber";
import CompleteDishes from "../Components/CompleteDishes";
import Clock from "react-live-clock";
import { Link } from "react-router-dom";
import { getUserData, logoutUser } from "../Services/Storage";
import { API_GET_ORDERS, API_ORDER_UPDATE } from "../Config/Endpoints";
import KitchenOrderLIst from "../Components/KitchenOrderLIst";
import Items from "../Components/Items";
import Table from "../Components/Table";
import TableItems from "../Components/TableItems";
const loggedInUser = getUserData();
import io from "socket.io-client";
import { ShowToast } from "../Services/Helpers";
// const socket = io("https://cottagebutcher.yatilabs.com:3100"); // Replace with your Socket.IO server URL

const KitchenOrder = () => {
  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showFirst, setList] = useState(false);
  const [showSecond, setItem] = useState(false);
  const [showThird, setItemFirst] = useState(false);
  const [showFour, setItemSecond] = useState(false);
  const [showFive, setItemThird] = useState(false);
  const [newOrder, setNewOrder] = useState(false);
  const [isDoneLoading, setIsDoneLoading] = useState(false);

  useEffect(() => {
    if (!loggedInUser.isLoggedIn) {
      window.location = "/login";
    }
  });

  // useEffect(() => {
  //   // Listen for 'message' event from the server
  //   socket.on("connect", () => {
  //     console.log("Socket.IO client connected");
  //   });

  //   const handleNewOrder = (data) => {
  //     console.log("new-order", data);
  //     ShowToast("success", "New Order");
  //     setNewOrder(true);
  //     fetchOrders();
  //   };

  //   socket.on("new-order", handleNewOrder);

  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     socket.off("new-order", handleNewOrder);
  //   };
  // }, [newOrder]);

  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = async () => {
    await fetch(API_GET_ORDERS + "?statuspayment=5&statusdelivery=-1", {
      method: "GET",
      crossDomain: true,
      credentials: "include",
    })
      .then((response) => response.json())
      .then((data) => {
        // const inStockProducts = data.data.filter((product) => {
        //   // console.log(product.attributes['product.instock']);
        //   return product.attributes["product.instock"] === 1;
        // });
        console.log(data);
        setOrderList(data.items);
        // setIsLoading(false);
        // setProductList(data.data);
        // console.log(data.included);
        // setCategoriesList(data.included);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const productStatusUpdateCb = () => {
    console.log("CN");
    fetchOrders();
  };

  const handleDone = async (id) => {
    setIsDoneLoading(true);
    document.getElementsByTagName("body")[0].classList.add("remove-loader");
    await fetch(API_ORDER_UPDATE, {
      method: "POST",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order_id: id,
        delivery_status: 4,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("orderupdate", data);
        fetchOrders();
        setIsDoneLoading(false);
        document
          .getElementsByTagName("body")[0]
          .classList.remove("remove-loader");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="kitchen-menu-list">
        <img
          src="../../assets/images/logo.svg"
          alt="logo.svg"
          className="img-fluid kitchen-logo"
        />
        <h1 className="kitchen-heading-brand">Kitchen Order</h1>
        <h2 className="kitchen-heading">
          <Link to="/" className="logout" onClick={logoutUser}>
            <i className="fa fa-sign-in" aria-hidden="true"></i>
          </Link>
          <Clock format={"hh:mm:ss A"} ticking={true} timezone={"US/Pacific"} />
        </h2>
      </div>

      <div className="kitchen-items-outer">
        {orderList.map((order, key) => (
          <div className="kitchen-table-list">
            <div className="table-order-number">
              <TableNumber
                // TableCard="Table 05"
                TableCardId={`#${order["order.id"]}`}
                TableCardIn="Dine-in"
              // TableCardTime="07:13 time"
              />
            </div>
            <div className="order-table-completed">
              <h4>Completed: (1/{order.products.length})</h4>
              <div className="row">
                {order.products.map((product, key) => (
                  <div className="col-lg-3 col-md-6">
                    <CardPrice
                      ImagesKitchen="../../assets/images/category-1.jpg"
                      Name={`${product["order.product.name"]}`}
                      Quantity={`x${product["order.product.quantity"]}`}
                    //   ListSize="large size"
                    //   Extra="extra cheese"
                    />
                  </div>
                ))}
                {/* <div className="kitchen__cancel__order">
                  <div className="notes">
                    <input type="text" className="form-control" placeholder="Notes" />
                  </div>
                  <button type="button" className="visible-button">Complete</button>
                </div> */}
              </div>
            </div>
          </div>
        ))}

        {orderList.map((order, key) => (
          <div className="kitchen_order--list">
            <div className="row">
              {order.products.map((product, key) => (
                <div className="col-md-3">
                  {/* <KitchenOrderLIst IdNumber={`#${order["order.id"]}`}
                    ItemsBooking={order.products.length} ItemsComplete="02:30:02 PM" Dine="Take away" Item={`${product["order.product.name"]}`}
                    Quantity={`x${product["order.product.quantity"]}`} /> */}
                </div>
              ))}
            </div>
          </div>
        ))}
        {orderList.map((order, key) => (
          <div className="kitchen_order--list">
            <div className="row">
              <div className="col">
                <div className="card">
                  <Table
                    IdNumber={`#${order["order.id"]}`}
                    ItemsBooking={order.products.length}
                    TableCardIn={order["order_type"]}
                  />
                  {order.products.map((product, key) => (
                    <div className="ss">
                      <TableItems
                        product={product}
                        order={order}
                        Item={`${product["order.product.name"]}`}
                        Quantity={`x${product["order.product.quantity"]}`}
                        updateCb={productStatusUpdateCb}
                      />
                    </div>
                  ))}
                  <div className="card-footer">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Notes"
                      value={order["order.comment"]}
                    />
                    <button
                      type="button"
                      className="visible-button  all-items-button"
                      onClick={() => handleDone(order["order.id"])}
                    >
                      {/* <img src="../../assets/images/check.png" alt="" /> */}
                      {/* {isDoneLoading ? (
                        <i class="fa fa-spinner fa-spin"></i>
                      ) : null}{" "} */}
                      Done
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default KitchenOrder;
