import React, { useEffect, useState, useRef } from "react";
import {
  deleteLocalData,
  getLocalData,
  getUserData,
  setUserData,
} from "../Services/Storage";
import SimpleReactValidator from "simple-react-validator";
import { API_LOGIN } from "../Config/Endpoints";
import { ShowToast } from "../Services/Helpers";

const loggedInUser = getUserData();

const Login = () => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState("");
  const [isBtnLoading, setIsBtnLoading] = useState("");
  const simpleValidatorLogin = useRef(new SimpleReactValidator());
  const [, forceUpdateLogin] = useState();
  useEffect(() => {
    if (loggedInUser.isLoggedIn) {
      window.location = "/";
    }
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    const formValid = simpleValidatorLogin.current.allValid();
    if (!formValid) {
      simpleValidatorLogin.current.showMessages();
      forceUpdateLogin(1);
    } else {
      setIsBtnLoading(true);
      console.log(email);
      console.log(password);
      await fetch(API_LOGIN, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        crossDomain: true,
        credentials: "include",
        body: JSON.stringify({ email, password }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          console.log(data.success);
          if (!data.success) {
            ShowToast("error", data.message);
          } else {
            ShowToast("success", "Logged In.");
            setUserData(data);
            const redirectUrl = getLocalData("redirect");
            if (redirectUrl) {
              deleteLocalData("redirect");
              window.location = redirectUrl;
            } else {
              window.location = "";
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
      setIsBtnLoading(false);
    }
  };
  return (
    <>
      <div className="log-in">
        <div className="file-form">
          <div className="logo-image-dark">
            <img src="../../assets/images/logo_dark.svg" alt="" />
          </div>
          <div className="details-user-login">
            <div className="form-heading">
              <h3>Login</h3>
              <p>
                Please fill details below to login into your account
              </p>
            </div>
            <form id="submit-form">
              <label for="Input1" className="label-name">
                Email address
              </label>
              <input
                type="email"
                className="input-control"
                id="Input1"
                placeholder="name@example.com"
                onChange={(e) => setEmail(e.target.value)}
                onBlur={() =>
                  simpleValidatorLogin.current.showMessageFor("email")
                }
              />
              {simpleValidatorLogin.current.message(
                "email",
                email,
                "required|email"
              )}
              <label for="Input2" className="label-name">
                Password
              </label>
              <input
                type="password"
                className="input-control"
                id="Input2"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onBlur={() =>
                  simpleValidatorLogin.current.showMessageFor("password")
                }
              />
              {simpleValidatorLogin.current.message(
                "password",
                password,
                "required"
              )}
              <div className="log-in-button">
                <button
                  type="submit"
                  className="theme-button"
                  form="submit-form "
                  onClick={handleLogin}
                  disabled={isBtnLoading}
                >
                  Log in
                  <i className={isBtnLoading ? `fa fa-spin fa-spinner` : ``} />
                </button>
              </div>
              <p class="password-forget">In case you forget your password then please contact with your adminstrator.
              </p>
            </form>
          </div>
        </div>
        <div className="images-f">
          <img src="../../assets/images/knife_bg.svg" alt="" />
        </div>
      </div>

    </>

  );
};

export default Login;
