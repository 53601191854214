import React, { useEffect, useState } from "react";
import { API_UPDATE_ORDER_PRODUCT_STATUS } from "../Config/Endpoints";

const TableItems = (props) => {
  const { product, order, updateCb } = props;
  const [show, setShow] = useState(false);

  useEffect(() => {
    // console.log(product);
  });

  const handleProductStatusUpdate = async (e) => {
    e.preventDefault();
    await fetch(API_UPDATE_ORDER_PRODUCT_STATUS, {
      method: "POST",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        order_id: order["order.id"],
        product_id: product["order.product.id"],
        delivery_status: product["order.product.statusdelivery"] != 4 ? 4 : -1, //deliverd
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("orderupdate", data);
        updateCb();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="card-body">
        <div className="list--items__order">
          <label
            class="form-check-label"
            for={`gridCheck${product["order.product.id"]}`}
          >
            {props.Item} <span>{props.Quantity}</span>
            <label>{props.Label}</label>
          </label>
          <input
            class="form-check-input"
            type="checkbox"
            id={`gridCheck${product["order.product.id"]}`}
            name={`gridCheck${product["order.product.id"]}`}
            onClick={(e) => handleProductStatusUpdate(e, product)}
            checked={
              product["order.product.statusdelivery"] === 4 ? "checked" : null
            }
          />
          {/* <input class="form-check-input" type="checkbox" id="gridCheck" /><p onClick={() => setShow(!show)} for="gridCheck"> {props.Item}  <span>{props.Quantity}</span><label>{props.Label}</label></p> */}
          <div className="image-box-check">
            {/* <input class="form-check-input" type="checkbox" id="gridCheck" /> */}
            {/* {show ? <img src="../../assets/images/check-right.png" alt="" /> : null} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TableItems;
