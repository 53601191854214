import React from "react";

const CardPrice = (props) => {
  return (
    <>
      <div className="customer-dealer">
        {/* <img src={props.ImagesKitchen} alt="" className="img-fluid" /> */}
        <div className="outer-kitchen">
          <h3 className="kitchen-heading">{props.Name}</h3>
          <h4 className="kitchen-heading">{props.Quantity}</h4>
        </div>
        {/* <ul className="kitchen-items-list">
                    <li>
                        {props.ListSize}
                    </li>
                    <li>
                        {props.Extra}
                    </li>
                </ul> */}
        {/* <button className="visible-button">Complete</button> */}
        {/* <button className="not-visible-button">Cancel</button> */}
        <div className="box-right-click">
          <img
            src="../../assets/images/check-right.png"
            alt=""
            className="right-icon"
          />
        </div>
      </div>
    </>
  );
};

export default CardPrice;
