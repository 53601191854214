import { EncryptStorage } from "encrypt-storage";

export const encryptStorage = new EncryptStorage(
  process.env.REACT_APP_STORAGE_ENCRYPTION_KEY,
  {
    prefix: "@cottageButcherKitchen",
  }
);

export const setUserData = (data) => {
  console.log("data", data);
  encryptStorage.setItem("cbIsLoggedIn", true);
  encryptStorage.setItem("cbUser", JSON.stringify(data.user));
  encryptStorage.setItem("cbToken", data.token.accessToken, true);
};

export function getUserData() {
  let user = {
    isLoggedIn: encryptStorage.getItem("cbIsLoggedIn"),
    user: encryptStorage.getItem("cbUser"),
    token: encryptStorage.getItem("cbToken", true),
  };
  return user;
}

export function logoutUser() {
  encryptStorage.removeItem("cbIsLoggedIn");
  encryptStorage.removeItem("cbUser");
  encryptStorage.removeItem("cbToken");
  window.location = "";
}

export function setLocalDataObject(key, data) {
  encryptStorage.setItem(key, JSON.stringify(data));
}

export function getLocalDataObject(key) {
  return JSON.parse(localStorage.getItem(key));
}

export function deleteLocalDataObject(key) {
  encryptStorage.removeItem(key);
}

export function setLocalData(key, data) {
  encryptStorage.setItem(key, data);
}

export function getLocalData(key) {
  return encryptStorage.getItem(key);
}

export function deleteLocalData(key) {
  encryptStorage.removeItem(key);
}
