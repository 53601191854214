import React, { useEffect, useState, useRef } from "react";
import {
  ShowToast,
  getOrderTypeName,
  getProductsCount,
  parseAmount,
  parseOrderDetails,
} from "../../Services/Helpers";
import {
  STAT_DELIVERED,
  STAT_PROGRESS,
  STAT_UNFINISHED,
} from "../../Config/Constants";
import {
  API_CANCEL_ORDER,
  API_ORDER_DELIVERY_STATUS,
} from "../../Config/Endpoints";
import { getUserData } from "../../Services/Storage";
import html2canvas from "html2canvas";
import Bill from "./Bill";
import ItemPrint from "./ItemPrint";

let userData = getUserData();

const Aside = (props) => {
  const { currentOrder, orderList, onOrderAction } = props;
  const [orderAddress, setOrderAddress] = useState({ attributes: [] });
  const [productList, setProductList] = useState([]);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [isHide, setIsHide] = useState(true);

  const componentBillRef = useRef(null);
  const componentItemRef = useRef(null);

  setTimeout(() => setIsHide(false), 1000);

  const handleBillPrintClick = () => {
    const htmlContent = componentBillRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            @media print {
              body {
                margin: 0;
                padding: 0;
              }
            }
          </style>
        </head>
        <body>
          ${htmlContent}
          <script>
            window.onload = function() {
              window.print();
              window.onafterprint = function() {
                window.close();
              };
            };
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  const handleItemPrintClick = () => {
    const htmlContent = componentItemRef.current.innerHTML;
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            @media print {
              body {
                margin: 0;
                padding: 0;
              }
            }
          </style>
        </head>
        <body>
          ${htmlContent}
          <script>
            window.onload = function() {
              window.print();
              window.onafterprint = function() {
                window.close();
              };
            };
          </script>
        </body>
      </html>
    `);
    printWindow.document.close();
  };

  useEffect(() => {
    const details = parseOrderDetails(currentOrder, orderList);
    if (details.productList) {
      setProductList(details.productList);
    }
    if (details.address) {
      setOrderAddress(details.address);
    }
  }, [currentOrder]);

  const handleScheduleBtn = async (item, status) => {
    setIsActionLoading(true);
    await fetch(API_ORDER_DELIVERY_STATUS, {
      method: "POST",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + userData.token,
      },
      body: JSON.stringify({
        order_id: item["id"],
        delivery_status: status,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Status Updated", data);
        onOrderAction(data);
        setIsActionLoading(false);
        ShowToast("success", "Order updated");
      })
      .catch((error) => {
        console.log(error);
        setIsActionLoading(false);
      });
  };

  const handleCancelOrder = async (item) => {
    setIsActionLoading(true);
    await fetch(API_CANCEL_ORDER, {
      method: "POST",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + userData.token,
      },
      body: JSON.stringify({
        order_id: item["id"],
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        onOrderAction(data);
        setIsActionLoading(false);
        ShowToast("success", "Order Canceled");
      })
      .catch((error) => {
        console.log(error);
        setIsActionLoading(false);
      });
  };

  const handleCompleteBtn = async (item) => {
    setIsActionLoading(true);
    await fetch(API_ORDER_DELIVERY_STATUS, {
      method: "POST",
      crossDomain: true,
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + userData.token,
      },
      body: JSON.stringify({
        order_id: item["id"],
        delivery_status: STAT_DELIVERED,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Status Updated", data);
        onOrderAction(data);
        setIsActionLoading(false);
        ShowToast("success", "Order marked as completed");
      })
      .catch((error) => {
        console.log(error);
        setIsActionLoading(false);
      });
  };

  return (
    <>
      <aside>
        {!isHide && currentOrder["id"] ? (
          <>
            {" "}
            <div className="order-detail">
              <div className="order-detail--head">
                <div className="user-detail">
                  <h3>#{currentOrder["id"]}</h3>
                  <h3>{orderAddress.attributes["order.address.firstname"]}</h3>
                  <h4>{getProductsCount(currentOrder)} Items</h4>
                </div>
                <div className="status">
                  <p>
                    {getOrderTypeName(currentOrder.attributes["order_type"])}
                  </p>
                  <h6>Order Date/Time</h6>
                  <h4>{currentOrder.attributes["order.ctime"]}</h4>
                </div>
              </div>
              {currentOrder.attributes["is_schedule_delivery"] ? (
                <div className="schedule">
                  <p>
                    Delivery Date <span>Today</span>
                  </p>
                  <p>
                    Delivery Time<span>10:23 AM</span>
                  </p>
                </div>
              ) : null}

              <div className="notes">
                <h4>Notes</h4>
                <p>{currentOrder.attributes["order.comment"]}</p>
              </div>
              <div className="actions">
                <button className="theme-button disabled">
                  <img src="/assets/images/adjust.svg" alt="adjust" />
                  ADJUST
                </button>
                <button
                  className="theme-button"
                  onClick={(event) => {
                    event.preventDefault();
                    const confirm = window.confirm(
                      "Are you sure you want to continue?"
                    );
                    if (confirm) {
                      handleCancelOrder(currentOrder);
                    }
                  }}
                >
                  <img src="/assets/images/cancel.svg" alt="cancel" />
                  CANCEL
                </button>
                <button className="theme-button" onClick={handleBillPrintClick}>
                  <img src="/assets/images/print.svg" alt="" />
                  PRINT
                </button>
              </div>
              <div className="list-items">
                <h3>Items</h3>
                <div className="category_group">
                  <div className="head">
                    <div className="title">
                      <h4>Item </h4>
                      <p>{getProductsCount(currentOrder)} Items</p>
                    </div>
                    <button type="button" onClick={handleItemPrintClick}>
                      <img src="/assets/images/print_black.svg" alt="" />
                    </button>
                  </div>
                  <table>
                    <tbody>
                      {productList.map((item, key) => (
                        <tr key={key}>
                          <td>{item.attributes["order.product.name"]}</td>
                          <td align="right">
                            x {item.attributes["order.product.quantity"]}
                          </td>

                          <td align="right">
                            $
                            {parseAmount(
                              parseFloat(
                                item.attributes["order.product.quantity"]
                              ) *
                                parseFloat(
                                  item.attributes["order.product.price"]
                                )
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              {currentOrder.attributes["order.statusdelivery"] ===
              STAT_UNFINISHED ? (
                <div className="schedule-button">
                  <a
                    href="javascript:;"
                    className="theme-button black"
                    onClick={(event) => {
                      event.preventDefault();
                      const confirm = window.confirm(
                        "Are you sure you want to continue?"
                      );
                      if (confirm) {
                        handleScheduleBtn(currentOrder, STAT_PROGRESS);
                      }
                    }}
                  >
                    Schedule Order{" "}
                    {isActionLoading ? (
                      <i className="fa fa-spin fa-spinner"></i>
                    ) : null}
                  </a>
                </div>
              ) : null}

              {currentOrder.attributes["order.statusdelivery"] ===
              STAT_PROGRESS ? (
                <>
                  <div className="schedule-button">
                    <a
                      href="javascript:;"
                      className="theme-button gray"
                      onClick={(event) => {
                        event.preventDefault();
                        const confirm = window.confirm(
                          "Are you sure you want to continue?"
                        );
                        if (confirm) {
                          handleScheduleBtn(currentOrder, STAT_UNFINISHED);
                        }
                      }}
                    >
                      Move to Active
                      {isActionLoading ? (
                        <i className="fa fa-spin fa-spinner"></i>
                      ) : null}
                    </a>{" "}
                    <a
                      href="javascript:;"
                      className="theme-button black"
                      onClick={(event) => {
                        event.preventDefault();
                        const confirm = window.confirm(
                          "Are you sure you want to continue?"
                        );
                        if (confirm) {
                          handleScheduleBtn(currentOrder, STAT_DELIVERED);
                        }
                      }}
                    >
                      Mark Complete{" "}
                      {isActionLoading ? (
                        <i className="fa fa-spin fa-spinner"></i>
                      ) : null}
                    </a>
                  </div>
                </>
              ) : null}

              {currentOrder.attributes["order.statusdelivery"] ===
              STAT_DELIVERED ? (
                <div className="schedule-button">
                  <a
                    href="javascript:;"
                    className="theme-button black"
                    onClick={(event) => {
                      event.preventDefault();
                      const confirm = window.confirm(
                        "Are you sure you want to continue?"
                      );
                      if (confirm) {
                        handleScheduleBtn(currentOrder, STAT_PROGRESS);
                      }
                    }}
                  >
                    Move To Scheduled{" "}
                    {isActionLoading ? (
                      <i className="fa fa-spin fa-spinner"></i>
                    ) : null}
                  </a>
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <div className="empty">
            <img
              src="./assets/images/no_order.svg"
              alt="No Order Selected"
              width={60}
            />
            <p>No Order Selected</p>
          </div>
        )}

        {currentOrder["id"] ? (
          <>
            {" "}
            <div ref={componentBillRef} style={{ display: "none" }}>
              {" "}
              <Bill
                currentOrder={currentOrder}
                productList={productList}
                orderAddress={orderAddress}
              />
            </div>
            <div ref={componentItemRef} style={{ display: "none" }}>
              {" "}
              <ItemPrint
                currentOrder={currentOrder}
                productList={productList}
                orderAddress={orderAddress}
              />
            </div>
          </>
        ) : null}
      </aside>
    </>
  );
};

export default Aside;
