import React from "react";
import { parseAmount } from "../../Services/Helpers";

const ItemPrint = (props) => {
  const { currentOrder, orderAddress, productList } = props;
  return (
    <>
      <table style={{ padding: "10px", width: "100%" }}>
        <tbody>
          <tr>
            <td>
              <p
                style={{ fontSize: "18px", color: "#000", marginBottom: "0px" }}
              >
                ORD: #{currentOrder["id"]}
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <p
                style={{ fontSize: "18px", color: "#000", marginBottom: "0px" }}
              >
                Date/Time:{currentOrder.attributes["order.ctime"]}
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <table style={{ width: "100%", marginTop: "10px" }}>
                <thead
                  style={{ fontSize: "18px", color: "#000", textAlign: "left" }}
                >
                  <tr>
                    <th style={{ borderBottom: "1px solid #000" }}>QTY ITEM</th>
                    <th style={{ borderBottom: "1px solid #000" }}>TOTAL</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: "17px", color: "#000" }}>
                  {productList.map((item, key) => (
                    <tr key={key}>
                      <td
                        style={{
                          borderBottom: "1px solid #adababd6",
                          padding: "7px 0",
                        }}
                      >
                        {item.attributes["order.product.name"]} X{" "}
                        {item.attributes["order.product.quantity"]}
                      </td>
                      <td
                        style={{
                          borderBottom: "1px solid #adababd6",
                          padding: "7px 0",
                        }}
                      >
                        $
                        {parseAmount(
                          parseFloat(
                            item.attributes["order.product.quantity"]
                          ) * parseFloat(item.attributes["order.product.price"])
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "18px",
                  color: "#000",
                  marginTop: "20px",
                }}
              >
                We value your feedback. Visit us on:
                <br />
                https://cottagebutcher.com/
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ItemPrint;
