import React, { useState } from "react";
import { logoutUser } from "../../Services/Storage";
import Clock from "react-live-clock";

const Header = (props) => {
  const { onTabSelection } = props;
  const [selectedTab, setSelectedTab] = useState("active");
  const [isLogoModalOpen, setIsLogoModalOpen] = useState(false);
  const handleOnTabClick = (tab) => {
    setSelectedTab(tab);
    onTabSelection(tab);
  };

  return (
    <>
      <header>
        <div className="logo" onClick={setIsLogoModalOpen}>
          <img src="./assets/images/logo.svg" alt="Cottage Butcher" />
        </div>
        <nav>
          <button
            type="button"
            className={`${selectedTab === "active" ? "active" : null}`}
            onClick={() => handleOnTabClick("active")}
          >
            Active Orders
          </button>
          <button
            type="button"
            className={`${selectedTab === "scheduled" ? "active" : null}`}
            onClick={() => handleOnTabClick("scheduled")}
          >
            Scheduled
          </button>
          <button
            type="button"
            className={`${selectedTab === "completed" ? "active" : null}`}
            onClick={() => handleOnTabClick("completed")}
          >
            Completed
          </button>
        </nav>
        <div className="page-title">
          <h3>
            {selectedTab.toUpperCase()} ORDERS
            {/* (<span>10</span>) */}
          </h3>
        </div>
        <div className="current-date">
          <h4>
            {" "}
            <Clock
              format={"ddd, MMM D YYYY, HH:mm:ss"}
              ticking={true}
              timezone={"Canada/Eastern"}
            />
          </h4>
        </div>
      </header>

      {isLogoModalOpen && (
        <>
          <div
            className="overlay"
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabindex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog">
              <div className="action-buttons">
                {/* <button type="button" onClick={(e) => handleHardRefresh(e)}>
                  <img src="../../assets/images/refresh.svg" alt="" />
                  <span>Hard Refresh</span>
                </button> */}
                <button
                  type="button"
                  className="open-button"
                  onClick={(event) => {
                    event.preventDefault();
                    const confirmLogout = window.confirm(
                      "Are you sure you want to logout?"
                    );
                    if (confirmLogout) {
                      logoutUser();
                    }
                  }}
                >
                  <img src="../../assets/images/power-off.svg" alt="" />
                  <span>Log Out</span>
                </button>
                <button
                  type="button"
                  className="open-button close"
                  onClick={(e) => setIsLogoModalOpen(false)}
                >
                  <img src="../../assets/images/close.svg" alt="" />
                  <span> Close</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
